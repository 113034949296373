<template>
  <!-- 行驶数据 -->
  <div class="chart scrollbar" :class="{
    [$style.chart]: true,
    [$style.large]: large
  }">
    <div :class="$style.rankChart">
      <ol :class="{
        [$style.topA]: true,
        [$style.animed]: !large && animed
      }">
        <template v-for="(item, index) in vData">
          <li v-if="index < half" :key="index" :style="large ? '' : `transition-delay: ${100 * index}ms;`">
            <span :class="$style.num">{{index + 1}}</span>
            <span :class="$style.label">{{ item.numPlate }}</span>
            <span :class="$style.value">{{ parseInt(item.totalMiles) || 0 }}km</span>
          </li>
        </template>
      </ol>
      <ol :class="{
        [$style.topB]: true,
        [$style.animed]: !large && !animed
      }">
        <template v-for="(item, index) in vData">
          <li v-if="index >= half" :key="index" :style="large ? '' : `transition-delay: ${100 * (index - half)}ms;`">
            <span :class="$style.num">{{index + 1}}</span>
            <span :class="$style.label">{{ item.numPlate }}</span>
            <span :class="$style.value">{{ parseInt(item.totalMiles) || 0 }}km</span>
          </li>
        </template>
      </ol>
    </div>
  </div>
</template>

<style module lang="scss">
// module
.large {
  height: 15rem;
  overflow: auto;

  .rankChart {
    display: flex;

    ol {
      flex: 1;
      padding: 0 2rem 0 0;
      overflow: hidden;

      &.topB {
        padding: 0 0 0 2rem;
        position: static;
        width: auto;
        border-left: 1px dashed rgba($color: #5961A6, $alpha: .5);
      }
    }

    li {
      padding: .4rem 0;
      font-size: .8rem;
      line-height: 1.2rem;

      .num {
        width: 1.1rem;
        height: 1.2rem;
        line-height: 1.2rem;
      }

      .value {
        font-size: .9rem;
      }
    }

    .topA {
      li {
        .num {
          font-size: .7rem !important;
        }
      }
    }
  }
}

.rankChart {
  position: relative;

  li {
    padding: .3rem 0;
    display: flex;
    font-size: .7rem;
    line-height: 1rem;
    align-items: center;
    transition: all .5s;
    opacity: 1;
    transform-style: preserve-3d;
    transform: rotateX(0);

    .num {
      width: 1rem;
      height: 1.1rem;
      line-height: 1.1rem;
      text-align: center;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .label {
      flex: 1;
      margin: 0 .6rem;
      color: #5961A6;
    }

    .value {
      font-size: .8rem;
    }
  }

  .animed {
    li {
      opacity: 0;
      transform: rotateX(180deg);
      backface-visibility: hidden;
    }
  }

  .topA {
    li {
      &:nth-child(1) {
        .num {
          font-size: .6rem;
          color: #D6802A;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjcwLjI1NyUiIHkxPSI1NS42MDUlIiB4Mj0iNTAlIiB5Mj0iMzcuMDQlIiBpZD0iYSI+PHN0b3Agc3RvcC1jb2xvcj0iI0Y2QTg3QiIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiNFNUFDN0UiIG9mZnNldD0iMTAwJSIvPjwvbGluZWFyR3JhZGllbnQ+PGxpbmVhckdyYWRpZW50IHgxPSIxMi43MTElIiB5MT0iNS4wMTUlIiB4Mj0iNzUuNTglIiB5Mj0iOTAuMTExJSIgaWQ9ImIiPjxzdG9wIHN0b3AtY29sb3I9IiNGQUVDOEQiIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjRkVFQTg4IiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjxsaW5lYXJHcmFkaWVudCB4MT0iMTIuNzExJSIgeTE9IjUuMDE1JSIgeDI9Ijc1LjU4JSIgeTI9IjkwLjExMSUiIGlkPSJjIj48c3RvcCBzdG9wLWNvbG9yPSIjRjNEMTRGIiBvZmZzZXQ9IjAlIi8+PHN0b3Agc3RvcC1jb2xvcj0iI0ZGREEwMCIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48bGluZWFyR3JhZGllbnQgeDE9IjE2LjUzMyUiIHkxPSI1LjAxNSUiIHgyPSI3Mi45NTglIiB5Mj0iOTAuMTExJSIgaWQ9ImQiPjxzdG9wIHN0b3AtY29sb3I9IiNGQkQ2NEQiIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjRkJENjREIiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAyMS4zMzMgMCkiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTEzLjM4NCAwaDQuOTc0YTEgMSAwIDAgMSAuODA2IDEuNTkyTDE0LjQ1NiA4SDdMMTIuNTc4LjQwOEExIDEgMCAwIDEgMTMuMzg0IDB6IiBmaWxsPSJ1cmwoI2EpIi8+PHBhdGggZD0iTTYuNzE0IDBoNC45NzRhMSAxIDAgMCAxIC44MDYgMS41OTJMNy43ODYgOEguMzNMNS45MDguNDA4QTEgMSAwIDAgMSA2LjcxNCAweiIgZmlsbD0idXJsKCNhKSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMTMuOTkzIDApIi8+PGNpcmNsZSBmaWxsPSJ1cmwoI2IpIiBjeD0iMTAuMzMzIiBjeT0iMTEiIHI9IjkiLz48Y2lyY2xlIGZpbGw9InVybCgjYykiIGN4PSIxMC4zMzMiIGN5PSIxMSIgcj0iNyIvPjxlbGxpcHNlIGZpbGw9InVybCgjZCkiIGN4PSIxMC42NjciIGN5PSIxMSIgcng9IjYiIHJ5PSI2LjMzMyIvPjwvZz48L3N2Zz4=);
        }
      }
      &:nth-child(2) {
        .num {
          font-size: .6rem;
          color: #587D8F;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjcwLjI1NyUiIHkxPSI1NS42MDUlIiB4Mj0iNTAlIiB5Mj0iMzcuMDQlIiBpZD0iYSI+PHN0b3Agc3RvcC1jb2xvcj0iIzk2QzREMSIgb2Zmc2V0PSIwJSIvPjxzdG9wIHN0b3AtY29sb3I9IiM5QUM1RDMiIG9mZnNldD0iMTAwJSIvPjwvbGluZWFyR3JhZGllbnQ+PGxpbmVhckdyYWRpZW50IHgxPSIxMi43MTElIiB5MT0iNS4wMTUlIiB4Mj0iNzUuNTglIiB5Mj0iOTAuMTExJSIgaWQ9ImIiPjxzdG9wIHN0b3AtY29sb3I9IiNEOUYwRjgiIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjQkFERkVFIiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjxsaW5lYXJHcmFkaWVudCB4MT0iMTIuNzExJSIgeTE9IjUuMDE1JSIgeDI9Ijc1LjU4JSIgeTI9IjkwLjExMSUiIGlkPSJjIj48c3RvcCBzdG9wLWNvbG9yPSIjQUJDQ0UxIiBvZmZzZXQ9IjAlIi8+PHN0b3Agc3RvcC1jb2xvcj0iI0EzQzdEOSIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PGcgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMjAgMCkiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTEzLjA1IDBoNC45NzRhMSAxIDAgMCAxIC44MDYgMS41OTJsLTQuNDA4IDZhMSAxIDAgMCAxLS44MDYuNDA4SDguNjQyYTEgMSAwIDAgMS0uODA2LTEuNTkybDQuNDA4LTZBMSAxIDAgMCAxIDEzLjA1IDB6IiBmaWxsPSJ1cmwoI2EpIi8+PHBhdGggZD0iTTYuMzg0IDBoNC45NzRhMSAxIDAgMCAxIC44MDYgMS41OTJsLTQuNDA4IDZBMSAxIDAgMCAxIDYuOTUgOEgxLjk3NmExIDEgMCAwIDEtLjgwNi0xLjU5Mmw0LjQwOC02QTEgMSAwIDAgMSA2LjM4NCAweiIgZmlsbD0idXJsKCNhKSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMTMuMzMzIDApIi8+PGNpcmNsZSBmaWxsPSJ1cmwoI2IpIiBjeD0iMTAiIGN5PSIxMSIgcj0iOSIvPjxjaXJjbGUgc3Ryb2tlPSIjOUNCRENFIiBzdHJva2Utd2lkdGg9Ii41IiBmaWxsPSJ1cmwoI2MpIiBjeD0iMTAiIGN5PSIxMSIgcj0iNi43NSIvPjwvZz48L3N2Zz4=);
        }
      }
      &:nth-child(3) {
        .num {
          font-size: .6rem;
          color: #9C501A;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjEyLjcxMSUiIHkxPSI1LjAxNSUiIHgyPSI3NC45NTYlIiB5Mj0iODkuMjY3JSIgaWQ9ImEiPjxzdG9wIHN0b3AtY29sb3I9IiNGOEI5QTAiIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjRjFCRDlGIiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjxsaW5lYXJHcmFkaWVudCB4MT0iMTIuNzExJSIgeTE9IjUuMDE1JSIgeDI9Ijc1LjU4JSIgeTI9IjkwLjExMSUiIGlkPSJiIj48c3RvcCBzdG9wLWNvbG9yPSIjRTM5ODYyIiBvZmZzZXQ9IjAlIi8+PHN0b3Agc3RvcC1jb2xvcj0iI0RDOTM1RSIgb2Zmc2V0PSI5OC40NCUiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAyMCAwKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTMuMjQ0IDBoNC44MWExIDEgMCAwIDEgLjgxMyAxLjU4MmwtNC4yOTggNmExIDEgMCAwIDEtLjgxMy40MThoLTQuODFhMSAxIDAgMCAxLS44MTMtMS41ODJsNC4yOTgtNkExIDEgMCAwIDEgMTMuMjQ0IDB6IiBmaWxsPSIjQzk5Qzc4Ii8+PHBhdGggZD0iTTYuNzU2IDBoLTQuODFhMSAxIDAgMCAwLS44MTMgMS41ODJsNC4yOTggNkExIDEgMCAwIDAgNi4yNDQgOGg0LjgxYTEgMSAwIDAgMCAuODEzLTEuNTgybC00LjI5OC02QTEgMSAwIDAgMCA2Ljc1NiAweiIgZmlsbD0iI0M5OUM3OCIvPjxjaXJjbGUgZmlsbD0idXJsKCNhKSIgY3g9IjEwIiBjeT0iMTEiIHI9IjkiLz48Y2lyY2xlIHN0cm9rZT0iI0RDOEU1QSIgc3Ryb2tlLXdpZHRoPSIuNSIgZmlsbD0idXJsKCNiKSIgY3g9IjEwIiBjeT0iMTEiIHI9IjYuNzUiLz48L2c+PC9zdmc+);
        }
      }
    }
  }

  .topB {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      animed: false,
      interval: undefined,
    };
  },
  computed: {
    half() {
      const { vData } = this;

      return Math.ceil(vData.length / 2) || 1;
    },
  },
  mounted() {
    const { large } = this;

    this.interval = !large && setInterval(() => {
      this.animed = !this.animed;
    }, 3000);
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
};
</script>